import {
  ref,
  watch,
  computed
} from '@vue/composition-api'
import store from '@/store'
import {
  title
} from '@core/utils/filter'

// Notification
import {
  useToast
} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useDatasList() {
  // Use toast
  const modulename = "Data";
  const modulenamesub = "document";
  const toast = useToast()

  const refDataListTable = ref(null)

  // Table Handlers
  const tableColumns = [{
      key: 'name',
      label: 'Name',
      sortable: true
    },
    {
      key: 'documenttype',
      tdClass: 'text-right',
      thClass: 'text-right',
      label: 'Data Type',
      sortable: false
    },
    // { key: 'category', tdClass: 'text-right', thClass: 'text-right', label: 'Category Type', sortable: false },
    {
      key: 'actions',
      tdClass: 'text-right',
      thClass: 'text-right',
      sortable: false
    },
  ]
  const perPage = ref(10)
  const totalDatas = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refDataListTable.value ? refDataListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDatas.value,
    }
  })

  const refetchData = () => {
    if (refDataListTable.value) {
      refDataListTable.value.refresh()
    }
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchDatas = (ctx, callback) => {
    store
      .dispatch('document/fetchDatas', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value ? sortBy.value : 'created_at',
        sortDesc: isSortDirDesc.value ? 'desc' : 'asc',
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const datas = response.data.data
        const total = response.data.meta.total

        callback(datas)
        totalDatas.value = total
      })
      .catch((err) => {
        console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Data list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const editfunction = (data) => {
    var temp = data;
    var appdatax = {
      id: data.id,
      name: temp.name,
      desc: temp.desc,
      category: temp.category,
      customcategory: temp.customcategory,
      customvalue: temp.category == "Others" ? 1 : 0,
    };
    store
      .dispatch("document/editData", appdatax)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            text: modulename + " editted successfully",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          appData.value = undefined;

          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching the Bank",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      });
  };


  const deletefun = (ctx, callback) => {
    store
      .dispatch('document/deleteData', {
        id: ctx,
      })
      .then(response => {
        refetchData()

      })
      .catch((err) => {
        console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Datas list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const downloadfun = (ctx, callback) => {
    store
      .dispatch('document/downloadData', {
        id: ctx,
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();

        // refetchData()

      })
      .catch((err) => {
        console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Datas list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  // *=======================---*
  // *--------- UI ---------------------------------------*
  // *=======================---*

  const resolveDataRoleVariant = is_active => {
    if (is_active) return 'primary'
    else return 'danger'
  }

  return {
    fetchDatas,
    deletefun,
    downloadfun,
    tableColumns,
    perPage,
    currentPage,
    totalDatas,
    editfunction,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDataListTable,

    resolveDataRoleVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
